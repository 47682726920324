function scrollDetectDescription(){
 const element = document.getElementById("backToTop")
 const back = document.getElementById("scroll_description")
    $(document).ready(function() {
        $("#backToTop").click(function(event) {
            event.preventDefault();
            $("#scroll_description").animate({ scrollTop: 0 }, "slow");
            return false;
            });
    });
}

export { scrollDetectDescription }
