const totalTva = () => {
  const inputs = document.querySelectorAll('.tva-input');
  const totalTvas = document.querySelectorAll('.total-tva');

  if (inputs.length > 0) {
    inputs.forEach((input) => {
      if (input.value > 0) {
        totalTvas[input.dataset.position].classList.remove('d-none');
      }

    })
  }
}

export {totalTva};
