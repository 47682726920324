function changeLanguage(language) {
                    var element = document.getElementById("url");
                    element.value = language;
                    element.innerHTML = language;
                }

const showDropdown = () => {
  const buttons = document.querySelectorAll(".listDrop")
  buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        buttons.forEach(button => button.nextElementSibling.classList.remove('show'))
        const dropdown = button.nextElementSibling
        dropdown.classList.toggle("show");
    });
  })
}

// Close the dropdown if the user clicks outside of it
window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
 }


export { showDropdown }

