import mapboxgl from 'mapbox-gl';

 const mapElement = document.getElementById('map');


 if (mapElement) { // only build a map if there's a div#map to inject into
   mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;

const dataMarker = JSON.parse(mapElement.dataset.marker)
const mapMarker = [ dataMarker.lng, dataMarker.lat];
const latitude = [dataMarker.lat];

const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);


const radius = JSON.parse(mapElement.dataset.radius);



   const map = new mapboxgl.Map({
     container: 'map',
     style: 'mapbox://styles/mapbox/streets-v10',
     center: mapMarker,
     zoom: 6
 });

var geojson = {
 type: 'FeatureCollection',
 features: [{
   type: 'Feature',
   geometry: {
     type: 'Point',
     coordinates: mapMarker
   },
   properties: {
     title: 'Mapbox',
     description: 'Washington, D.C.'
   }
 },
]
};

map.on('load', function() {
      map.addSource("source_circle_500", {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": mapMarker
            }
          }]
        }
      });

      map.addLayer({
        "id": "circle500",
        "type": "circle",
        "source": "source_circle_500",
        "paint": {
          "circle-radius": {
            stops: [
              [0, 0],
              [20, metersToPixelsAtMaxZoom(radius, latitude)]
            ],
            base: 2
          },
          "circle-color": "green",
          "circle-opacity": 0.4
        }
      });
    });
}
