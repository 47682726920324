// imports
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';


// function definitions
const initSelect2 = () => {
  $('.client').select2();
};

// exports (~ public interface)
export { initSelect2 };