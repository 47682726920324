function addFieldModal(){
        var adds = document.getElementsByClassName('addLine')
        for (var i = 0; i < adds.length; i++) {
                 adds[i].addEventListener('click', function(e) {
                        const modalId = e.currentTarget.dataset.modal
                        var lines = document.querySelectorAll(`#${modalId} .more_group:not(.d-none)`);
                        if (lines.length == 0) {
                                const more_groups = document.querySelectorAll(`#${modalId} .more_group`);
                                if (more_groups) {
                                      for (var i = 0; i < more_groups.length; i++) {
                                              if (more_groups[i].classList.contains('d-none')) {
                                                    more_groups[i].classList.remove('d-none');
                                                    more_groups[i].querySelector('input[data-target="description"]').setAttribute('name', "prestation[line_invoices][0][description]");
                                                    more_groups[i].querySelector('input[data-target="number"]').setAttribute('name', "prestation[line_invoices][0][number]");
                                                    // more_groups[i].classList.add('d-flex');
                                              }
                                       }
                                }
                        }
                        else {
                                var newdiv = lines[lines.length - 1].cloneNode(true);
                                var newid = parseInt((newdiv.getAttribute('id')[newdiv.getAttribute('id').length - 1])) + 1;
                                newdiv.setAttribute('id', "prestation_lines_invoices_" + newid );
                                newdiv.querySelector('input[data-target="description"]').setAttribute('name', `prestation[line_invoices][${newid}][description]`);
                                newdiv.querySelector('input[data-target="number"]').setAttribute('name', `prestation[line_invoices][${newid}][number]`);
                                newdiv.querySelector('input[data-target="description"]').setAttribute('id', `prestation[line_invoices][${newid}][description]`);
                                newdiv.querySelector('input[data-target="number"]').setAttribute('id', `prestation[line_invoices][${newid}][number]`);
                                newdiv.querySelector('input[data-target="description"]').value = "";
                                newdiv.querySelector('input[data-target="description"]').placeholder = "Description";
                                newdiv.querySelector('input[data-target="number"]').value = "";
                                newdiv.querySelector('input[data-target="number"]').placeholder = "Montant";
                                $(`#${modalId} #groups`).append(newdiv);
                        }

                });
        }
};


export { addFieldModal }
