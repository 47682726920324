import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantity', 'lastName', 'firstName', 'remise', 'montantHt']

    connect() {
        let inputs = document.querySelectorAll('.trix-content')

        if (inputs.length > 0) {
            inputs.forEach(input => {
                // display & hide trix toolbar on click
                input.addEventListener('focus', () => {
                    input.parentElement.classList.add('focus')
                }, true)
                input.addEventListener('blur', () => {
                    input.parentElement.classList.remove('focus')
                }, true)
            })
        }
    }

  // Le rôle du builder est de gérer le comportoment de tous les inputs
  tva(e){
    // update the select tva field on description to make work nested-form
    const descriptionLatte = e.currentTarget.parentElement.previousElementSibling;
    const selectInput = descriptionLatte.querySelector('select');
    const position = Number(descriptionLatte.dataset.record);

    selectInput.name = `prestation[descriptions_attributes][${position}][tva]`;
    selectInput.id = `prestation_descriptions_attributes_${position}_tva`;
  }

  oneQty(e) {
    console.log(this.quantityTarget.value)
    if (this.quantityTarget.value == 0) {
        this.quantityTarget.previousElementSibling.classList.remove('disabled')
        this.quantityTarget.value = 1
    }
  }

  showSelects(e){
    const tvaSelectors = document.querySelectorAll('[data-target="tva"]')
    tvaSelectors.forEach(selector => {
        if (e.currentTarget.value == 0) {
            selector.classList.add('d-none')
        } else {
            selector.classList.remove('d-none')

        }
    })
  }

  showTva(e){
    const tvaSelectors = document.querySelectorAll('[data-target="tva"]')
    const tva1 = document.querySelector('.tva-input').value.length
    tvaSelectors.forEach(selector => {
        if (tva1 == 0) {
            selector.classList.add('d-none')
        } else {
            selector.classList.remove('d-none')

        }
    })
  }

  updateSelect(e){
    // when one tva input is changed, update all select fields
    const inputs = document.querySelectorAll('.tva-input');
    const tvaClasses = ['not_any', 'first_tva', 'second_tva', 'third_tva'];
    const boxSelects = document.querySelectorAll('.chosen-tva');
    const fields = ['<option value="not_any" data-position="0">Aucune</option>'];

    // build select fields
    inputs.forEach((input, index) => {

      if (Number(input.value) > 0){
        const option = `<option value="${tvaClasses[index + 1]}" data-position="${index + 1}">${index + 1}: ${input.value}%</option>`
        fields.push(option);
      }
    })

    boxSelects.forEach((box) => {
      // find which field is selected
      let selected = box.querySelector('option[selected]') ? box.querySelector('option[selected]').dataset.position : 0 ;

      // mark the new field as the selected one
      if (selected > 0){
        fields[selected] = `<option value="${tvaClasses[selected]}" selected='true' data-position="${selected}">${selected}: ${inputs[selected - 1].value}%</option>`
      } // > 0 because 0 - 1 is a problem

      // // remove old fields
      box.innerHTML = '';

      // insert updated fields
      fields.forEach((field) => {
         box.insertAdjacentHTML('beforeend', field);
      });

      // remove selected field for next
      if (selected > 0){
        fields[selected] = `<option value="${tvaClasses[selected]}" data-position="${selected}">${selected}: ${inputs[selected - 1].value}%</option>`;
      }
    });
  }

  updateNewSelect(e) {
      // update select field of the new description added with the btn add-association
    const inputs = document.querySelectorAll('.tva-input');
    const tvaClasses = ['first_tva', 'second_tva', 'third_tva'];
    const newSelectField = e.currentTarget.parentElement.previousElementSibling.querySelector('.chosen-tva');
    const fields = ['<option value="not_any" >Aucune</option>'];

     // build select fields
    inputs.forEach((input, index) => {
      if (Number(input.value) > 0){
        const field = `<option value="${tvaClasses[index]}" data-position="${index}">${index + 1}: ${input.value}%</option>`
        fields.push(field);
      }
    });

    // remove old fields
    newSelectField.innerHTML = '';

    // insert new fields
    fields.forEach((field) => {
      newSelectField.insertAdjacentHTML('beforeend', field);
    });
  }

  replaceThisWithNext(e){
    // if user delete an input, then his content is replaced with the next input
    const content = Number(e.currentTarget.value);
    const position = Number(e.currentTarget.dataset.position) + 1;
    const nextInputTva = document.querySelector(`input[data-position="${position}"]`);
    if (content == 0 && Number(nextInputTva.value) > 0){
      e.currentTarget.value = nextInputTva.value; // display new value
      nextInputTva.setAttribute('value', nextInputTva.value); // record new value in db
      nextInputTva.value = ''; // delete value in view
      // delete record from db on submit
      nextInputTva.removeAttribute('value');
      nextInputTva.removeAttribute('disabled');

      // remove good style if there is
      nextInputTva.classList.remove('is-valid');

      // Special case : the first tva content == 0 the third is fill and not the second
      const thirdInputTva = document.querySelector('input[data-position="2"]');
      if (position == 1 && Number(thirdInputTva.value) > 0 ){
        // it means nextInputTva is equal to second tva input

        nextInputTva.value = thirdInputTva.value;
        thirdInputTva.setAttribute('value', nextInputTva.value); // record new value in db
        thirdInputTva.value = ''; // delete value in view
        // delete record from db on submit
        thirdInputTva.removeAttribute('value');
        thirdInputTva.removeAttribute('disabled');

        // remove good style if there is
        thirdInputTva.classList.remove('is-valid');
      }
    }
  }

  setSelected(e){
      // change selected attribute when user change select field
      // remove attribute from old
      const oldSelected = e.currentTarget.querySelector('[selected]');
      if (oldSelected){
        oldSelected.removeAttribute('selected');
      }

      // set attribute on new selected
      const newSelected = e.currentTarget.querySelector(`[value='${e.target.value}']`);
      newSelected.setAttribute('selected', true);
      e.currentTarget.parentElement.value = e.target.value;
  }

  displayTotalTva(e){
    const totalTvas = document.querySelectorAll('.total-tva');
    if (e.currentTarget.value > 0)
      totalTvas[e.currentTarget.dataset.position].classList.remove('d-none');
    else
      totalTvas[e.currentTarget.dataset.position].classList.add('d-none');
  }

  addPictureInModal(e){
    const modal = document.querySelector('#modalRoomPicture .modal-body');
    const picture = e.currentTarget.querySelector('img').cloneNode(false);
    picture.style.width = '450px';
    picture.style.height = '450px';
    picture.style.margin = 'auto';
    picture.style.display = 'block';
    modal.innerHTML = '';
    modal.appendChild(picture);
  }

  replaceField(e){
    const director = document.getElementById('director')
    if (e.currentTarget.value == 'individu') {
        this.lastNameTarget.innerHTML = 'Nom';
        this.firstNameTarget.innerHTML = 'Prénom';
        director.classList.add('d-none');
    } else if (e.currentTarget.value == 'society') {
        this.lastNameTarget.innerHTML = 'Nom de société ';
        this.firstNameTarget.innerHTML = 'Forme juridique';
        director.classList.remove('d-none');
    }
  }

  toggleRemiseField(e){
    e.preventDefault();
    this.remiseTarget.classList.toggle('d-none');
    if (e.currentTarget.innerText == 'Ajouter une remise') {
        e.currentTarget.innerText = 'Supprimer la remise'
    } else {
        // si on supprime le champs de remise alors :
        e.currentTarget.innerText = 'Ajouter une remise'
    }
  }

  stockValue(e){
    const montantHt = e.currentTarget.value
  }

   controlTvaDescriptions(e){
        e.preventDefault();
        const tvaInput = document.getElementById("prestation_tva");
        const descriptionFields = document.querySelectorAll('.descriptions .nested-fields:not(.d-none)');
        // itérer sur descriptionFields : calcul chaque latte
        descriptionFields.forEach((latte) => {
            // init variables
            const tva = latte.querySelector('.chosen-tva');
            const unitPrice = latte.querySelector('.unit');
            if (tvaInput != null && (tva.value == 'not_any') && unitPrice.value.length > 0) {
                 tva.style.background = 'red';
              }
              else {
                  tva.style.background = 'white';
              }
           })
     }

}
