import { Controller } from "stimulus"
export default class extends Controller {
  link_to(e){
    document.location = e.currentTarget.dataset.href;
  }

  clicked(e){
    const button = e.currentTarget.querySelector('i')
    button.classList.toggle('fa-chevron-down');
    button.classList.toggle('fa-chevron-up');
    e.currentTarget.classList.toggle('coll-admin-clicked');
  }
}
