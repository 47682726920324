const countChar = () => {

  const input = document.querySelectorAll('trix-editor:not([data-count="false"])')[0];
  if (input){
    const characterCount = document.getElementById('characterCount');

    input.addEventListener('keyup', (e) => {
        if (input.value.length == 0) {
            characterCount.innerHTML = input.value.length;
        } else {
            characterCount.innerHTML = input.value.length - 11;
        }
    });
  }
}

export { countChar };

