import { Controller } from "stimulus"
export default class extends Controller {
    scrollTo(e){
        e.preventDefault()
        const target = e.currentTarget.dataset.target;
        console.log(target)
        document.getElementById(target).scrollIntoView({behavior: 'smooth'})
    }
    toggleCollapse(e){
        document.querySelectorAll("#accordionExample .fas.fa-minus").forEach((el) => {
            if (el.parentElement != e.currentTarget) {
                el.classList.remove("fa-minus")
                el.classList.add("fa-plus")
            }
        })
        e.currentTarget.querySelector(".fas").classList.toggle("fa-plus")
        e.currentTarget.querySelector(".fas").classList.toggle("fa-minus")
    }
    toggleSelected(e){
        // fonction inversé car label déclenche deux fois l'évent
        if (e.currentTarget.querySelector('[type="checkbox"]').checked){
            e.currentTarget.classList.add('checked')
        } else {
            e.currentTarget.classList.remove('checked')
        }
    }
    link_to(e){
        document.location = e.currentTarget.dataset.href;
    }

    clicked(e){
        const button = e.currentTarget.querySelector('i')
        button.classList.toggle('fa-chevron-down');
        button.classList.toggle('fa-chevron-up');
    }

    actived(e){
        e.currentTarget.classList.toggle('active');
    }

    visibilitySociety(e) {
        if (e.currentTarget.nextElementSibling.dataset.visibility == 'true') {
            e.currentTarget.nextElementSibling.innerText = 'non-visible'
            e.currentTarget.nextElementSibling.dataset.visibility = 'false'
        } else if (e.currentTarget.nextElementSibling.dataset.visibility == 'false'){
            e.currentTarget.nextElementSibling.innerText = 'visible'
            e.currentTarget.nextElementSibling.dataset.visibility = 'true'
        }
    }

    preview(e){
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            const reader = new FileReader();
            reader.onload = (event) => {
                document.getElementById('client-preview').src = event.currentTarget.result;
            }
            reader.readAsDataURL(e.currentTarget.files[0])
            document.getElementById('client-preview').classList.remove('d-none');

            let preview = document.querySelector('.client-photo');

            if (preview) {
                preview.remove();
            }
            else {
                document.querySelector('.fa-home').classList.add('d-none');
            }
        }
    }

    room_preview(e){
        let preview = e.currentTarget.closest('.room-card').querySelector('.photo-preview');

        if (e.currentTarget.files && e.currentTarget.files[0]) {
          const reader = new FileReader();
          reader.onload = (event) => {
            preview.src = event.currentTarget.result;
            preview.classList.remove('d-none');
          }
          reader.readAsDataURL(e.currentTarget.files[0])
        }

        console.log(e.currentTarget);
        console.log(e.currentTarget.closest('.room-card'));
    }

    toggleMenu(e){
        document.querySelector('.presta-form_left').classList.toggle('hide-right')
        document.querySelector('.presta-form_right').classList.toggle('hide-right')
    }
    removeHover(e){
        const target = e.currentTarget
        target.parentElement.classList.add('d-none')
        setTimeout(() => {
            target.parentElement.classList.remove('d-none')
        }, 1)
    }
}
