import 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/dark.css';

const datePick = (e) => {
       const options = {
    allowInput: true,
    dateFormat: 'd-m-Y',
  };

    const pick = document.getElementsByClassName('datepicker')
    pick.datepicker = pick.flatpickr(options);
  }

export { datePick };


