$(function(){
$('form').on('click', '.remove_fields', function(event) {
    $(this).closest('.field').remove()
    event.preventDefault()
  });


  // const element = document.getElementById("add_fields_visible")
  $('form').on('click', '.add_fields', function(event) {
    //   if (element) {
    // element.classList.remove('d-none');
    // }
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).before($(this).data('fields').replace(regexp, time))
    event.preventDefault()
  });
})

