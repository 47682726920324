const endWorkSign = () => {
  // var wrap = document.getElementsByClassName("wrapper_end_sign");
  // if (wrap.length > 0) {
  const form = document.querySelector(".sign-end-work");
  if (form) {
    var canvas = document.querySelectorAll('.signature-pad');
    var signaturePads = []
    const inputs = document.querySelectorAll('.record_sign');
    const btnsClear = document.querySelectorAll('.clear');

      // select receipt
      const receipt = document.querySelector('#prestation_receipt');

    canvas.forEach((canvas) => {
      // Adjust canvas coordinate space taking into account pixel ratio,
      // to make it look crisp on mobile devices.
      // This also causes canvas to be cleared.
      function resizeCanvas() {
          // When zoomed out to less than 100%, for some very strange reason,
          // some browsers report devicePixelRatio as less than 1
          // and only part of the canvas is cleared then.
          var ratio =  Math.max(window.devicePixelRatio || 1, 1);
          canvas.width = canvas.offsetWidth * ratio;
          canvas.height = canvas.offsetHeight * ratio;
          canvas.getContext("2d").scale(ratio, ratio);
      }
      window.onresize = resizeCanvas;
      resizeCanvas();

      signaturePads.push( new SignaturePad(canvas, {
          backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
        }));
    });

   // insert picture into the form to save it before submitting
      const requireRemove = document.getElementById("remove_require_sign");
      const requireRemoveTwo = document.getElementById("remove_require_sign_two");
      if (requireRemove) {
      requireRemove.removeAttribute("required");
      }
      if (requireRemoveTwo) {
      requireRemoveTwo.removeAttribute("required");
      }
    // insert picture into the form to save it before submitting
    form.addEventListener('submit', function (e) {
      e.preventDefault(); // => prevent submitting
      if (!signaturePads[0].isEmpty() && !signaturePads[1].isEmpty() && receipt.innerText.length > 0) {
        signaturePads.forEach((canva, index) => {
          var path = canva.toDataURL('image/png');
          inputs[index].value = path;
          e.target.submit(); // => submit form
        })
      } else {
        if (receipt.innerText.length == 0) {
        receipt.classList.add('border');
        receipt.classList.add('border-danger');
        return alert("Remplissez les réserves.");
        }
        else {
        return alert("SVP signez avant d'enregistrer.");
        }
      }
    });

    // delete picture on click
    btnsClear.forEach((btn, index) => {
      btn.addEventListener('click', function (e) {
        e.preventDefault();
        signaturePads[index].clear();
        inputs[index].value = '';
      });
    })
  }
};

export { endWorkSign }

