import $ from 'jquery';
import 'trix';
import 'trix/dist/trix.css'
import "bootstrap";
import 'mapbox-gl/dist/mapbox-gl.css'; // <-- you need to uncomment the stylesheet_pack_tag in the layout!
import { initMapbox } from '../plugins/init_mapbox';
import "../components/burger.js";
import "../components/add_field.js";
import {addFieldModal} from "../components/add_fields_modal.js";
import {choiceOtherHabitation} from "../components/choice_other_habitation.js";
import {showDropdown} from "../components/dot_dropdown.js";
import { disableEnableTva } from "../components/disable_tva.js";
import { datePick } from '../components/datePicker.js';
import { phoneMail } from '../components/btnPhone';
import { destroyCheck } from '../components/destroyCheck';
import { previewImageOnFileSelectLogo } from '../components/photo_preview_logo';
import { previewImageOnFileSelect } from '../components/photo_preview_validation';
import { previewFiles } from '../components/photo_previews';
import { countChar } from '../components/count_char';
import { scrollToTop } from '../components/scroll_to_top';
import { sign } from '../components/sign';
import { endWorkSign } from '../components/end_work_sign';
import { dashboard } from '../components/adminDashboard';
import { totalTva } from '../components/display_total_tva';
import { deleteTopButton } from '../components/delete_top_button';
import { closeAlertInvoice } from '../components/close_alert';
import { deleteModalInvoice } from '../components/close_modal_invoice';
import { scrollDetect } from '../components/remove_back_to_top';
import { scrollDetectDescription } from '../components/back_to_top_form_description';
import { initSelect2 } from '../components/add_select2';
import { initNavMenu } from '../components/add_nav_menu';
import '../components/show_password.js';

$( document ).ready(function() {
  datePick();
  destroyCheck();
  previewImageOnFileSelectLogo();
  previewImageOnFileSelect();
  previewFiles();
  countChar();
  scrollToTop();
  sign();
  endWorkSign();
  phoneMail();
  disableEnableTva();
  dashboard();
  totalTva();
  showDropdown();
  choiceOtherHabitation();
  deleteModalInvoice();
  deleteTopButton();
  scrollDetect();
  scrollDetectDescription();
  addFieldModal();
  initSelect2();
  initNavMenu();


  // To make tooltip works
  $("body").tooltip({ selector: '[data-toggle=tooltip]' });
});

import "controllers"
