const deleteTopButton = () => {
    const element = document.getElementById('backTop')
    if (element)
    {
        const elementButton = document.getElementById('back2Top')
        elementButton.style.cssText='display:none !important';
    }

}

export { deleteTopButton }
