import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['menu', 'burger']
    toggleMenu(e) {
        const width =   window.innerWidth || document.documentElement.clientWidth ||
                        document.body.clientWidth
        if (width <= 750) {
            e.preventDefault();
            this.burgerTarget.classList.toggle('open')
            this.menuTarget.classList.toggle('open')
            document.querySelector('.Burger-close').classList.toggle('d-none')
            document.querySelector('.Burger-close').addEventListener('click', () => {this.toggleMenu(e)})
        }
    }
    toggleConnexion(e){
        e.preventDefault();
        document.querySelector('.Connexion-modal').classList.toggle('open')
        document.querySelector('.Connexion-close').classList.toggle('d-none')

        // à la fermeture de la modale, reinit le carousel pour afficher le formulaire de connexion
        if (!document.querySelector('.Connexion-modal').classList.contains('open')) {
            document.querySelector('.Connexion-modal .active').classList.remove('active')
            document.querySelector('.Connexion-modal .carousel-item').classList.add('active')
        }
        document.querySelector('.Connexion-close').addEventListener('click', () => {this.toggleConnexion(e)})
    }
}
