import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["add_description", "template"]

  // Le rôle du nested-form est de controller les associations entre elles et le model

  add_association(event) {
    event.preventDefault()
    const wrappers = document.querySelectorAll(".descriptions .nested-fields:not(.d-none)");
    const indexes = wrappers.length > 0 ? [...wrappers].map(wrapper => wrapper.dataset.record) : "-1"
    let record_index;
    if (indexes === "-1") {
      record_index = parseInt(Math.max(indexes)) + 1
     }
     else {
      record_index = parseInt(Math.max(...indexes)) + 1
     }
        var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, record_index);
    this.add_descriptionTarget.insertAdjacentHTML('beforebegin', content);
  }


  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.classList.remove('d-flex')
    item.classList.add('d-none');
  }

  fix_position(event){
    const wrappers = document.querySelectorAll(".descriptions .nested-fields:not(.d-none)");
    wrappers.forEach((wrapper, index) => {
      wrapper.dataset.position = index + 1;
      wrapper.querySelector('.position').value = index + 1; // to record description's position
    })
  }
  moveup(e){
    e.preventDefault();
    var wrapper = $(e.currentTarget).closest('.nested-fields');
    // console.log(wrapper.prevAll('.nested-fields:not(.d-none)'))
    wrapper.prevAll('.nested-fields:not(.d-none)').first().before(wrapper);
    var wrappers = document.querySelectorAll(".descriptions .nested-fields:not(.d-none)");
    wrappers.forEach((wrapper, index) => {
      wrapper.dataset.position = index + 1;
      wrapper.querySelector('.position').value = index + 1;
    })
  }
  movedown(e){
    e.preventDefault();
    var wrapper = $(e.currentTarget).closest('.nested-fields');
    wrapper.nextAll('.nested-fields:not(.d-none)').first().after(wrapper);
    var wrappers = document.querySelectorAll(".descriptions .nested-fields:not(.d-none)");
    wrappers.forEach((wrapper, index) => {
      wrapper.dataset.position = index + 1;
      wrapper.querySelector('.position').value = index + 1;
    })
  }
}






















