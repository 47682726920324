import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['input', 'label', 'errorMessage']

    connect(e){
        this.inputTargets.forEach((input) => {
            input.addEventListener('change', () => {
                this.toggleLabel(input)
            })
        })
    }
    toggleLabel(input){
        if (input.value.length > 0) {
            input.nextElementSibling.classList.add('filled')
        } else {
            input.nextElementSibling.classList.remove('filled')
        }
    }

    displayLoginErrors(e){
        this.errorMessageTarget.classList.remove('d-none')
    }

    refreshPage(e){
        // window.location.reload();
        const [data, status, xhr] = event.detail;
        document.querySelector('.Navbar').remove()
        document.querySelector('body').insertAdjacentHTML('afterbegin', xhr.response)
        document.querySelector("[data-target='closeModalConnexion']").click()
        document.querySelector('body').insertAdjacentHTML(
            'afterbegin',
            "\
                <div class='alert alert-info alert-dismissible' role='alert'>\
                <button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>×</span></button>\
                    Vous êtes maintenant connecté.\
                </div>\
            "
        )
        setTimeout(() => {
            document.querySelector("[data-target='modalConnexion']").remove()
            const myDropdown = document.querySelector('.my-dropdown');
            if (myDropdown) {
              const myMenu = document.querySelector('.my-menu');
              
              myDropdown.addEventListener("click", (e) => {
                myMenu.classList.toggle("d-none")
              })
            }
        }, 1000)
    }

    focusEmail(e){
        setTimeout(() => {
            document.querySelector('.carousel-item.active [data-focus="emailConnexion"]').focus()
        }, 400)
    }

    redirectTo(e){
        const url = e.target.dataset.url;
        window.location.replace(url);
    }

    displaySignupErrors(e){
        console.log('errors')
    }
}
