const initNavMenu = () => {
    const myDropdown = document.querySelector('.my-dropdown');
    if (myDropdown) {
      const myMenu = document.querySelector('.my-menu');
      
      myDropdown.addEventListener("click", (e) => {
        myMenu.classList.toggle("d-none")
      })
    }
}

export { initNavMenu };
