
$(function(){
  $(".eye_icon").on('click',function() {
    if ($(".password").attr('type') === 'password') {
        $(".password").attr('type', 'password');
    } else {
        $(".password").attr('type', 'text');
    }
  });
})
