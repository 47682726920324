const deleteModalInvoice = () => {
  const btn_modal_invoices = document.getElementsByClassName("btn-close-modal-invoice");
  if (btn_modal_invoices.length > 0) {
      for (var i = 0; i < btn_modal_invoices.length; i++) {
          if (btn_modal_invoices[i]) {
              btn_modal_invoices[i].addEventListener('click', (e) => {
                e.preventDefault();
                const test = document.getElementById('myAlertInvoice');
                setTimeout(function () {
                  test.style.visibility = "hidden";;
                }, 0);
              });
          }
      }
  }
}

export { deleteModalInvoice }


