import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        'importBtn',
        'importJobBtn',
        'checkbox',
        'closeModal',
        'add_description',
        'newDescription',
        'ici',
        'result',
        'search',
        'searchForm'
    ]

    reinitializeForm(e){
        document.querySelectorAll('[data-initialize="field"]').forEach((field) => {
            field.value = ''
        })
        document.querySelector('[data-initialize="trix"]').classList.remove('trix-error')
    }

    showJobBtn(e) {
        document.querySelector('[data-toggle="importJobBtn"]').classList.remove('d-none')
    }

    showImportBtn(e) {
        document.querySelector('[data-toggle="importBtn"]').classList.remove('d-none')
    }

    reinitializeModal(e){
        // reninit search
        this.searchTarget.value = ''
        Rails.fire(this.searchFormTarget, 'submit')

        // reninit
        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.checked = false
                checkbox.closest('.description').classList.remove('checked')
            }
        })
        this.importBtnTarget.setAttribute('disabled', 'true')
        this.importBtnTarget.classList.add('d-none')
        this.importJobBtnTarget.setAttribute('disabled', 'true')
        this.importJobBtnTarget.classList.add('d-none')
    }

    activeBtns(e){
        if (e.currentTarget.checked) {
            this.importBtnTarget.removeAttribute('disabled')
            this.importJobBtnTarget.removeAttribute('disabled')
        } else if (this.checkboxTargets.every((currentValue) => currentValue.checked == false)) {
                this.importBtnTarget.setAttribute('disabled', 'true')
                this.importJobBtnTarget.setAttribute('disabled', 'true')
        }
    }

    removeEmptyLatte(e){
        // efface la latte vide avant d'importer
        const currentDescription = e.currentTarget.closest('.description')
        if (currentDescription.querySelector('trix-editor').value.length == 0){
            currentDescription.remove()
        }
    }

    importDescriptions(e){
        const descriptions = []
        // récupérer les datas des descriptions à importer
        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked){
                const currentDescription = checkbox.closest('.description')
                const detail = currentDescription.querySelector('.detail').innerHTML
                const unit_price = currentDescription.querySelector('.unit_price').innerText

                let description = {
                    detail: detail,
                    unit_price: unit_price,
                }

                descriptions.push(description)
            }
        })
        if (descriptions.length > 0) {
            // importation des descriptions
            const add_description = document.querySelector('[data-target="add_description"]')
            const container = document.querySelector('.descriptions')
            descriptions.forEach(description => {
                add_description.click();
                const lattes = container.querySelectorAll('.description:not(.d-none)')
                const lastLatte = lattes[lattes.length - 1]
                // insert elements
                lastLatte.querySelector('trix-editor').innerHTML = description['detail']
                lastLatte.querySelector('input[data-import="unit_price"]').value = description['unit_price']
            })

            // fermer la modal
            this.closeModalTarget.click();

            // Reinitialiser la modal
            this.reinitializeModal()
        }
    }

    importJobDescriptions(e){
        const descriptions = []
        // récupérer les datas des descriptions à importer
        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked){
                const currentDescription = checkbox.closest('.description')
                const detail = currentDescription.querySelector('.detail').innerHTML

                let description = {
                    detail: detail,
                }

                descriptions.push(description)
            }
        })
        if (descriptions.length > 1) {
            // afficher le message d'erreur : une importation posssible
            document.querySelector('[data-error="importWarning"]').classList.remove('d-none')
        } else if (descriptions.length == 1) {
            // cacher message d'erreur
            document.querySelector('[data-error="importWarning"]').classList.add('d-none')

            // importation de la description
            const trix = document.getElementById('prestation_name')
            trix.editor.loadHTML("")
            trix.editor.insertHTML(descriptions[0].detail)
            document.getElementById('characterCount').innerText = document.getElementById('prestation_name').innerText.length

            // fermer la modal
            this.closeModalTarget.click();

            // Reinitialiser la modal
            this.reinitializeModal()
        }
    }

    toggleForm(e) {
        e.preventDefault();
        document.querySelector('form[data-target="newDescription"]').classList.toggle('d-none')
        this.newDescriptionTarget.classList.toggle('d-none')
    }

    targetDescription(e) {
        // remove target
        document.querySelectorAll('.nested-fields:not(.d-none)').forEach(description => {
            description.classList.remove('target')
        })
        e.currentTarget.closest('.nested-fields').classList.add('target')
    }

    saveDescription(e) {
        const currentDescription = document.querySelector('.target')
        const title = document.querySelector('input[name="title"]').value

        if (title) {
            document.querySelector('input[name="title"]').classList.remove('border-danger')

            if (currentDescription.querySelector('trix-editor').innerText) {
                currentDescription.querySelector('trix-editor').parentElement.classList.remove('trix-error')

                currentDescription.querySelector('trix-editor').parentElement.classList.remove('trix-error')
                const detail = currentDescription.querySelector('trix-editor').innerHTML
                const unit_price = currentDescription.querySelector('input[data-import="unit_price"]').value

                const form = document.querySelector('form[data-target="newDescription"]')
                form.querySelector('#my_description_detail').value = detail
                form.querySelector('#my_description_unit_price').value = unit_price
                form.querySelector('#my_description_title').value = title
                Rails.fire(form, 'submit');
            } else {
                currentDescription.querySelector('trix-editor').parentElement.classList.add('trix-error')
            }
        } else {
            document.querySelector('input[name="title"]').classList.add('border-danger')
        }
    }

    toggleEditDescription(e){
        e.preventDefault();
        const currentDescription = e.currentTarget.closest('.description')
        currentDescription.querySelectorAll('[data-display="show"]').forEach(element => {
            element.classList.toggle('d-none')
            element.classList.toggle('d-flex')
        })
        currentDescription.querySelectorAll('[data-display="form"]').forEach(element => {
            element.classList.toggle('d-none')
            element.classList.toggle('d-flex')
        })

    }

    reinitializeEditDescription(e){
        const form = e.currentTarget.closest('.description')
        form.querySelectorAll('[data-init="detail"]')[1].value = form.querySelectorAll('[data-init="detail"]')[0].innerText
        form.querySelectorAll('[data-init="unit_price"]')[1].value = form.querySelectorAll('[data-init="unit_price"]')[0].innerText
    }

    validation(e){
        e.preventDefault()
        const form = e.currentTarget.closest('form');
        if ((form.querySelector('.trix-content').value.length > 0) && (form.querySelector('#my_description_title').value.length > 0)) {
            form.querySelector('.trix-content').parentElement.classList.remove('trix-error')
            Rails.fire(form, 'submit');
        }
        if (form.querySelector('.trix-content').value.length === 0 ) {
            form.querySelector('.trix-content').parentElement.classList.add('trix-error')
            }
        if (form.querySelector('#my_description_title').value.length === 0 ) {
            form.querySelector('#my_description_title').style.borderColor = "red";
            }
    }

    onEditSuccess(event){
        const [data, status, xhr] = event.detail;
        event.currentTarget.innerHTML = xhr.response
    }

    onCreateSuccess(event){
        const [data, status, xhr] = event.detail;
        this.iciTarget.insertAdjacentHTML('beforeend', xhr.response);
        const form = event.currentTarget;
        // clean input title
        // form.querySelector('#my_description_title').innerText = ''
        // clean trix detail input
        form.querySelector('trix-editor').innerText = ''
        form.querySelector('#my_description_detail_trix_input_my_description').value = ''
        // clean unit_price input
        form.querySelector('[data-target="unit_price"]').value= ''

        // hide form & display btn
        form.classList.add('d-none')
        this.newDescriptionTarget.classList.remove('d-none')
    }

    onSaveSuccess(event){
        const [data, status, xhr] = event.detail;
        document.querySelector('[data-target="bibliothequeModal"]').insertAdjacentHTML('beforeend', xhr.response)
        document.querySelector('input[name="title"]').classList.remove('border-danger')
        document.querySelector("[data-modal='close']").click()

        // display alert
        const alert = document.querySelector('[data-target="descriptionSaved"]')
        alert.classList.add('show')
        setTimeout(()=>{
            alert.classList.remove('show')
        }, 1500)

        // reinit title input
        document.querySelector('input[name="title"]').value = ''
    }

    onDeleteSuccess(event){
        event.currentTarget.closest('form').remove()
    }

    displayResult(event){
        const [data, status, xhr] = event.detail;
        if ( document.querySelector('[data-target="bibliothequeModal"]') ) {
            document.querySelector('[data-target="bibliothequeModal"]').innerText = ''
            document.querySelector('[data-target="bibliothequeModal"]').insertAdjacentHTML('beforeend', xhr.response)
        } else {
            this.resultTarget.innerText = ''
            this.resultTarget.insertAdjacentHTML('beforeend', xhr.response)
        }
        event.currentTarget.querySelector('input[type="text"]').blur()
        event.currentTarget.querySelector('input[type="text"]').focus()
    }

    search(e){
        const form = e.currentTarget.closest('form')
        Rails.fire(form, 'submit')
    }

    changeTrixValue(e){
        // mise en forme du trix (retour à la ligne)
        const description = e.currentTarget.closest('.my_description')
        const trixContainer = description.querySelector('[data-target="trix-container"]')
        if (trixContainer.classList.contains('d-flex')) {
            const trix = trixContainer.querySelector('trix-editor')
            setTimeout(() => {
                trix.editor.setSelectedRange([0, trix.getAttribute('data-value').length])
                trix.editor.insertHTML(trix.getAttribute('data-value'))
            }, 0.1)
        }
    }
}
