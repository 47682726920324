const dashboard = () => {
  const linkToDash = document.getElementById('link-to-dash');

  if (linkToDash){
    const linkToSocieties = document.getElementById('link-to-societies');
    const linkToUsers = document.getElementById('link-to-users');

    const contentDash = document.getElementById('content-dash');
    const contentSocieties = document.getElementById('content-societies');
    const contentUsers = document.getElementById('content-users');

    linkToDash.addEventListener('click', (e)=>{
      if (!e.currentTarget.classList.contains('selected')) {
        e.currentTarget.classList.add('selected');
        linkToSocieties.classList.add('unselected');
        linkToUsers.classList.add('unselected');
        e.currentTarget.classList.remove('unselected');
        linkToSocieties.classList.remove('selected');
        linkToUsers.classList.remove('selected');

        contentDash.classList.remove('d-none');
        contentSocieties.classList.add('d-none');
        contentUsers.classList.add('d-none');
      }
    });

    linkToSocieties.addEventListener('click', (e)=>{
      if (!e.currentTarget.classList.contains('selected')) {
        e.currentTarget.classList.add('selected');
        linkToUsers.classList.add('unselected');
        linkToDash.classList.add('unselected');
        e.currentTarget.classList.remove('unselected');
        linkToUsers.classList.remove('selected');
        linkToDash.classList.remove('selected');

        contentSocieties.classList.remove('d-none');
        contentUsers.classList.add('d-none');
        contentDash.classList.add('d-none');
      }
    });

    linkToUsers.addEventListener('click', (e)=>{
      if (!e.currentTarget.classList.contains('selected')) {
        e.currentTarget.classList.add('selected');
        linkToDash.classList.add('unselected');
        linkToSocieties.classList.add('unselected');
        e.currentTarget.classList.remove('unselected');
        linkToDash.classList.remove('selected');
        linkToSocieties.classList.remove('selected');

        contentUsers.classList.remove('d-none');
        contentDash.classList.add('d-none');
        contentSocieties.classList.add('d-none');
      }
    });
  }
}
export {dashboard};
