const sign = () => {
  var wrap = document.getElementsByClassName("wrapper_sign");
  if (wrap.length > 0) {
    var canvas = document.getElementById('frame');
    // Adjust canvas coordinate space taking into account pixel ratio,
    // to make it look crisp on mobile devices.
    // This also causes canvas to be cleared.
    function resizeCanvas() {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }
    window.onresize = resizeCanvas;
    resizeCanvas();

    var signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });


    // insert picture into the form to save it before submitting
      const requireRemove = document.getElementById("remove_require_sign");
      if (requireRemove) {
      requireRemove.removeAttribute("required");
      }
    document.querySelector(".sign-form").addEventListener('submit', function (e) {
      e.preventDefault();
      // => prevent submitting

      if (signaturePad.isEmpty()) {
        return alert("SVP signez avant d'enregistrer.");
      } else {
        var path = signaturePad.toDataURL('image/png');
        document.querySelector('.picture-path').value = path;
        e.target.submit(); // => submit form
      }
    });

    // delete picture on click
    document.getElementById('clear').addEventListener('click', function (e) {
      e.preventDefault();
      signaturePad.clear();
      document.querySelector('.picture-path').value = '';
    });
  }
};
export { sign }

