// Active or disable inputs tva
const disableEnableTva = () => {
  var firstTva = document.getElementById("prestation_tva");

  if (firstTva) {
    var secondTva = document.getElementById('prestation_second_tva');
    var thirdTva = document.getElementById('prestation_third_tva');

    firstTva.addEventListener('keyup', (event) => {
      if (Number(firstTva.value) > 0) {
        // active next_input
        secondTva.disabled = false;
      }
      else {
        //disable next_input
        secondTva.disabled = true;
      }
    });

    secondTva.addEventListener('keyup', (event) => {
      if (Number(secondTva.value) > 0) {
        // active next_input
        thirdTva.disabled = false;
      }
      else {
        // disable next_input
        thirdTva.disabled = true;
      }
    });

  }
}

export { disableEnableTva }



