const previewFiles = () => {
  const elementPhotoFile = document.getElementById('browse');

  if (elementPhotoFile) {
    elementPhotoFile.addEventListener('change', () => {
      var preview = document.querySelector('#preview');
      var files   = document.getElementById('browse').files;

      function readAndPreview(file) {

        // Make sure `file.name` matches our extensions criteria
        if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
          var reader = new FileReader();

          reader.addEventListener("load", function () {
            var image = new Image();
            image.height = 100;
            image.title = file.name;
            image.src = this.result;
            preview.appendChild( image );
          }, false);

          reader.readAsDataURL(file);
        }
      }
      if (files) {
        [].forEach.call(files, readAndPreview);

      }
      document.getElementById('text-previews').innerHTML="Cliquez sur les photos pour suppprimer";
    });
  }
 //clear the file list when image is clicked
  $('body').on('click','img',function(){
      $('#browse').val("");
      $('#preview').html("");
      document.getElementById('text-previews').innerHTML="";

  });



}
export { previewFiles };

