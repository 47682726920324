function scrollDetect(){
 const element = document.getElementById("back2Top")
    $(document).ready(function() {
        $("#back2Top").click(function(event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
            });
    });
    window.onscroll = function() {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
        if (currentScroll > 150 ){
            $('#back2Top').fadeIn();
        }
        else {
            $('#back2Top').fadeOut();
            if (element) {
                element.style.cssText='opacity:1 !important';
            }
        }
    };
}

export { scrollDetect }
