import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['errorMessage', 'input']

    displayPasswordForm(e){
        const [data, status, xhr] = e.detail;
        document.querySelector('.col-11').innerHTML = xhr.response
    }

    displayErrorMessage(e){
        console.log('error')
        this.errorMessageTarget.classList.remove('d-none');
        this.inputTarget.classList.add('border', 'border-danger')
    }
}
