import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['priceHt', 'quantity', 'montantHt', 'montantTtc', 'totalHt', 'totalTtc', 'remise', 'tva', 'lineTva']
    // Le rôle de l'operator est d'exécuter tous les calculs nécessaire au devis

    calcul(e){
       // init
        const descriptionFields = document.querySelectorAll('.descriptions .nested-fields:not(.d-none)');

        // itérer sur descriptionFields : calcul chaque latte
        descriptionFields.forEach((latte) => {
            // init variables
            const tva = latte.querySelector('.chosen-tva');
            const unitPrice = latte.querySelector('.unit');
            const quantity = latte.querySelector('.qty');
            const montantHt = latte.querySelector('.montantHt');
            const montantTtc = latte.querySelector('.montantTtc');
            const remise = latte.querySelector('.remise')
            const totalLineTva = latte.querySelector('.lineTva');

            // si pas de tva ni qté mais prix unitaire
            if ((tva == null || tva.value == 'not_any') && quantity.value.length == 0 && unitPrice.value.length > 0) {
                // unitPrice.value = 1;
                montantHt.value = (Number(unitPrice.value)).toFixed(2);
                montantTtc.value = (Number(unitPrice.value)).toFixed(2);

                // stocker montantHt dans les data pour calculer la remise
                montantHt.dataset.amount = (Number(unitPrice.value)).toFixed(2);
                montantTtc.dataset.amount = (Number(unitPrice.value)).toFixed(2);
            }
            // si pas de tva mais prix unitaire et qté
            else if ((tva == null || tva.value == 'not_any') && quantity.value.length > 0 && unitPrice.value.length > 0) {
              let amount = (unitPrice.value * quantity.value).toFixed(2);

                // Si il y a une remise, alors calculer la remise, sinon faire le calcul
                if (remise.value != '') {
                    montantHt.value =  amount - (amount * parseFloat(remise.value) / 100);
                    montantTtc.value = amount - (amount * parseFloat(remise.value) / 100);
                } else {
                    montantHt.value = amount;
                    montantTtc.value = amount;
                }

                // stocker les montants non remisés dans les data au cas où l'on supprime la remise
                montantHt.dataset.amount = amount;
                montantTtc.dataset.amount = amount;
                if (tva != null ) {
                totalLineTva.value = montantTtc.value - montantHt.value
            }
            }
            // si tva et prix unitaire et quantité
            else if ((tva == null || tva.value != 'not_any') && quantity.value.length > 0 && unitPrice.value.length > 0) {
                // get amount of tva
                const elementTva = tva.querySelector(`[value=${tva.value}]`);
                const amountTva = 1 + parseFloat(elementTva.innerText.slice(3, elementTva.innerText.length - 1)) / 100

                if (remise.value != '') {
                    const amount = (unitPrice.value * quantity.value);
                    const calculHt = amount - (amount * parseFloat(remise.value) / 100)

                    montantHt.value = calculHt.toFixed(2);
                    montantTtc.value = (calculHt * amountTva).toFixed(2);
                    totalLineTva.value = montantTtc.value - montantHt.value
                } else {
                    montantHt.value = (unitPrice.value * quantity.value).toFixed(2);
                    montantTtc.value = (amountTva * unitPrice.value * quantity.value).toFixed(2);
                    totalLineTva.value = montantTtc.value - montantHt.value
                }

                // stocker montantHt dans les data pour calculer la remise
                montantHt.dataset.amount = (unitPrice.value * quantity.value).toFixed(2);
                montantTtc.dataset.amount = (amountTva * unitPrice.value * quantity.value).toFixed(2);
            }

        });
    }


    calculTotal(e){
        // init
        const descriptionFields = document.querySelectorAll('.descriptions .nested-fields:not(.d-none)');
        const totalHts = document.querySelectorAll('.totalHt');
        const totalTtcs = document.querySelectorAll('.totalTtc');
        const totalRemises = document.querySelectorAll('.totalRemise');
        let countHt = 0;
        let countTtc = 0;
        let countRemise = 0;

        // calcul total latte by latte
        descriptionFields.forEach((latte) => {
            const montantHt = latte.querySelector('.montantHt');
            const montantTtc = latte.querySelector('.montantTtc');
            const remise = latte.querySelector('.remise');
            const unitPrice = latte.querySelector('.unit').value;
            const quantity = latte.querySelector('.qty').value;


            // calcul ht
            countHt += Number(montantHt.value);

            // calcul ttc
            countTtc += Number(montantTtc.value);

            //  if (totalLineTva === '') {
            //     totalLineTva.value = (countTtc - countHt)
            // }
             // calcul Montant Tva
            // Si il y a une remise, calculer son montant en nombre d'euros remisés
            if (remise.value != '') {
                countRemise += ((unitPrice * quantity) * parseFloat(remise.value) / 100);
            }
        });

        // insert result into html;
        totalTtcs[0].value = countTtc; // input hidden
        totalTtcs[1].innerText = countTtc.toFixed(2) + ' €'; // visible total (<p>)

        totalHts[0].value = countHt; // input hidden
        totalHts[1].innerText = countHt.toFixed(2) + ' €'; // visible total (<p>)

        totalRemises[0].value = countRemise;//
        totalRemises[1].innerText =  countRemise.toFixed(2) + ' €'; // visible total (<p>)
    }

    calculTotalTva(e){
        // init
        const descriptionFields = document.querySelectorAll('.descriptions .nested-fields:not(.d-none)');
        const totalTvas = document.querySelectorAll('.total-tva');
        const tvaInputs = document.querySelectorAll('.tva-input');
        const countTvas = [0, 0, 0];
        const position = {not_any: 0, first_tva: 1, second_tva: 2, third_tva: 3}

        // iterate on descriptionFields : latte by latte
        descriptionFields.forEach((latte) => {

            // check which tva is selected
            const selectTva = latte.querySelector('.chosen-tva');
            const tvaSelected = position[selectTva.value];

            // if there is a tva selected
            if (tvaSelected > 0) {
                // then calcul montant tva
                const montantTtc = latte.querySelector('.montantTtc').value;
                const montantHt = latte.querySelector('.montantHt').value;
                const montantTva = montantTtc - montantHt;

                // then insert montantTva into countTvas
                countTvas[tvaSelected - 1] += montantTva
            }
        });

        // When all tva are counted, insert total into html only if tva-input is filled
        countTvas.forEach((total, index) => {
            const inputTva = totalTvas[index].querySelector('input');
            const textTva = totalTvas[index].querySelector('h4:last-child');

            if (tvaInputs[index].value > 0) {
                inputTva.value = total;
                textTva.innerText = total.toFixed(2) + ' €';
            } else {
                inputTva.setAttribute('value', 'false');
            }
        });
    }

    removeRemise(e) {
        if (e.currentTarget.innerText == 'Ajouter une remise') {
            this.remiseTarget.value = ''
            this.montantHtTarget.value = this.montantHtTarget.dataset.amount
            this.montantTtcTarget.value = this.montantTtcTarget.dataset.amount
        }
    }

    calculRemise(e) {
        // Si pas de tva, donc le prix ht = prix tcc et la remise s'applique au deux
        if (this.tvaTarget.value == 'not_any'){
            if (this.remiseTarget.value > 0) {
                const remise  = parseFloat(this.remiseTarget.value);
                const montantHt = parseFloat(this.montantHtTarget.dataset.amount)
                const prixReduit = montantHt - (montantHt * remise / 100)
                this.montantHtTarget.value = prixReduit
                this.montantTtcTarget.value = prixReduit
            } else {
                this.remiseTarget.value = ''
                this.montantHtTarget.value = this.montantHtTarget.dataset.amount
                this.montantTtcTarget.value = this.montantTtcTarget.dataset.amount
            }
        }
        // Il y a une tva, donc la remise ne s'applique qu'au prix ht
    }

    lessQty(e) {
        e.preventDefault();
        let quantity = e.currentTarget.nextElementSibling.value
        if (quantity > 0) {
            e.currentTarget.nextElementSibling.value = parseInt(quantity)
            e.currentTarget.classList.add('disabled')
            e.currentTarget.setAttribute('disabled', true)
        } else {
            e.currentTarget.nextElementSibling.value = parseInt(quantity) - 1
        }
    }

    MoreQty(e) {
        e.preventDefault();
        let quantity = e.currentTarget.previousElementSibling.value
        if (quantity == '' || quantity == 0) {
            e.currentTarget.previousElementSibling.value = 1
            e.currentTarget.previousElementSibling.previousElementSibling.classList.remove('disabled')
            e.currentTarget.previousElementSibling.previousElementSibling.removeAttribute('disabled')
        } else {
            e.currentTarget.previousElementSibling.value = parseInt(quantity) + 1
        }
    }
}
