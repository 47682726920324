const previewImageOnFileSelectLogo = () => {
  // we select the photo input
  const input = document.getElementById('logo-input');
  if (input) {
    // we add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input);
    });
  }
}

const displayPreview = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById('logo-preview').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    document.getElementById('logo-preview').classList.remove('d-none');
    document.getElementById('logo-preview').style="display:block";
    document.getElementById('text-preview').innerHTML="Cliquez sur le logo pour suppprimer";
  }

  var orig_src = $('#logo-preview').prop('src');
  $('#logo-preview').click(function () {
      $('#logo-preview').hide();
      $('#logo-preview').prop('src', orig_src);
      $('#logo-input').val('');
      document.getElementById('text-preview').innerHTML="";
  });
}

export { previewImageOnFileSelectLogo };
