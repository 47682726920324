   const choiceOtherHabitation = (e) => {
      const checkOther = document.getElementById('chkOther')
      if ( checkOther) {
        checkOther.addEventListener('click', (e) => {
        const txtOther = document.getElementById("txtOther");
        txtOther.disabled = chkOther.checked ? false : true;
        if (!txtOther.disabled) {
            txtOther.focus();
            const btn_habitations = document.getElementsByClassName("category-choice");
            txtOther.classList.remove('d-none')

            if (btn_habitations) {
              for (var i = 0; i < btn_habitations.length; i++) {
                if (btn_habitations[i].classList.contains('active')) {
                    btn_habitations[i].classList.remove('active');
                }
              }
            }
          }
        });
        }
      }

export { choiceOtherHabitation }
