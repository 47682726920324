import { Controller } from "stimulus"
export default class extends Controller {
  remove(e){
    e.preventDefault();
    const input = e.currentTarget.parentElement.parentElement.querySelector('textarea');
    input.value = '';
    e.currentTarget.parentElement.parentElement.submit();
  }

  clicked(e){
    // init variables
    // console.log(e.currentTarget.classList.contains('active'))
    const formGroup = e.currentTarget.parentElement;
    const inputs = formGroup.querySelectorAll('input');
    const labels = formGroup.querySelectorAll('label');

    if (e.currentTarget.classList.contains('active')){
      e.currentTarget.classList.remove('active');
      e.currentTarget.nextElementSibling.checked = 'false';

    } else {
      const txtOther = document.getElementById("txtOther");
              if (!txtOther.disabled) {
                document.getElementById("chkOther").checked = false;
                txtOther.value = ""
                txtOther.disabled.true
                txtOther.classList.add('d-none')
             }
      // check if there is a label clicked
      labels.forEach((label) => {
        if (label.classList.contains('active')){
            label.classList.remove('active');
            label.nextElementSibling.checked = false;
        }
      })
      // add style on label clicked
      e.currentTarget.classList.add('active');
    }
  }


  showQFlat(e){
    document.querySelector('.qFlat').classList.remove('d-none');
  }

  removeQFlat(e){
    document.querySelector('.qFlat').classList.add('d-none');
  }

  showQHouse(e){
    document.querySelector('.qHouse').classList.remove('d-none');
  }

  removeQHouse(e){
    document.querySelector('.qHouse').classList.add('d-none');
  }
}
