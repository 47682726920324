const destroyCheck = () => {
  const input = document.querySelector('.destroy-check');
  if (input){
    const name = document.querySelector('.society-name').textContent;
    const btnDestroy = input.nextElementSibling;
    input.addEventListener('keyup', (e) => {
      if (e.currentTarget.value === name){
        btnDestroy.classList.remove('disabled');
      } else {
        if (!(btnDestroy.classList.contains('disabled'))){
          btnDestroy.classList.add('disabled');
        };
      }
    });
  }
}
export { destroyCheck };
