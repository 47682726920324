import { ValidationController } from "stimulus-validation"

export default class extends ValidationController {
  //  static rules = {
  //   detail: { presence: { allowEmpty: false }, detail: true},
  // }


    static get rules() {
    return {
       detail: { presence: { allowEmpty: false }, detail: true}
    };
  }
    afterValidate({ el, attr }) {
    this.errorMessageEl(el).textContent = this.errorMessage(attr)
  }

  errorMessageEl(el) {
    return el.closest(".field").querySelector(".error-message")
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : ""
  }
}
