const phoneMail = () => {
  const btn_phone = document.getElementById("phone_hidde");
  const btn_phone_visible = document.getElementById("phone_visible");
  if (btn_phone) {
    btn_phone.addEventListener('click', (e) => {
      e.preventDefault();
      btn_phone.style='display:none';
      btn_phone_visible.classList.remove('d-none');
    });
  }

  const btn_mail = document.getElementById("mail_hidde");
  const btn_mail_visible = document.getElementById("mail_visible");
  if (btn_mail) {
    btn_mail.addEventListener('click', (e) => {
      e.preventDefault();
      btn_mail.style='display:none';
      btn_mail_visible.classList.remove('d-none');
    });
  }
}

export { phoneMail }
