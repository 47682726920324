import { Controller } from "stimulus"
export default class extends Controller {

    controlDescriptions(e){
        e.preventDefault();
        const form = document.querySelector('form');
        let countError = 0;
        let descriptions = document.querySelectorAll('.descriptions .description:not(.d-none)');
        descriptions.forEach(description => {
            let trix = description.querySelector('.trix-content');
            if (!trix.value) {
                trix.parentElement.classList.add('trix-error');
                countError += 1;
            }
        })
        if (countError == 0) {
            form.submit();
        }
    }



    checkRequired(e){
        e.preventDefault()
        let error = 0
        document.querySelectorAll('[data-check="toCheck"]').forEach(input => {
            if (input.value.length == 0 ){
                error ++
                input.classList.add('has-error')
            }
        })
        if ( error == 0 ){
            Rails.fire(document.getElementById('new_user'), 'submit');
        } else {
                console.log('beta')
            document.querySelector('[data-target="error-message"]').classList.remove('d-none')
        }

    }
}
