import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        if (window.outerHeight < 800) {
            const modal = document.querySelector('[data-target="modalConnexion"]')
            modal.classList.add('full-screen')
        }
    }
}
